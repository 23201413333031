.baze-logo {
    height: 80px !important;
    width: 90px !important;
}

@media screen and (max-width: 400px) {
    .baze-logo {
        height: 60px !important;
        width: 60px !important;
    }
}
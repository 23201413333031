.img-responsive {
    width: 100% !important;
    height: 320px !important;
    object-position: center top;
}

.img-responsive-top {
    width: 100% !important;
    height:400px !important;
    object-position: center top;
}
span.member_learn_more {
    position: relative;
    font-weight: 600;
    line-height: 1.55em;
    background-image: linear-gradient(currentColor,currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
}

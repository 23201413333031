div.light-orange-accents,
div.light-orange-accents * {
    border-color: #63656A;
}

.block.news-content .news-header .sidebar .sidebar-outline,
.block.news-content .news-body .sidebar .sidebar-outline {
    height: 100%;
    border-left: 1px solid;
    border-color: inherit;
    padding-left: 30px
}

.block.news-content .news-header .sidebar .sidebar-outline>div:not(:last-child),
.block.news-content .news-body .sidebar .sidebar-outline>div:not(:last-child) {
    margin-bottom: 30px
}

.block.news-content .news-header h1 {
    font-size: 30px;
    line-height: 35px
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-header h1 {
        font-size: 36px;
        line-height: 40px
    }
}

.block.news-content .news-header .content .head {
    margin-bottom: 20px
}

.block.news-content .news-body {
    margin-bottom: 20px
}

.block.news-content .news-body .content>.body p {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 20px
}

.block.news-content .news-body .content>.body p.caption,
.block.news-content .news-body .content>.body p.credit {
    font-size: .9em;
    font-style: italic;
    line-height: 1.42857
}

.block.news-content .news-body .content>.body p.caption {
    margin-bottom: 5px
}

.block.news-content .news-body .content>.body p.credit {
    margin: 0
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-body .content>.body .wrap-image {
        width: 50%;
        margin: 0
    }

    .block.news-content .news-body .content>.body .wrap-image .image {
        margin: 0
    }

    .block.news-content .news-body .content>.body .wrap-image.wrap-image-right {
        float: right;
        padding: 9px 0 0 30px
    }

    .block.news-content .news-body .content>.body .wrap-image.wrap-image-left {
        float: left;
        padding: 9px 30px 0 0
    }
}

.block.news-content .news-body .content>.body.drop-cap>p:first-of-type {
    min-height: 52px
}

.block.news-content .news-body .content>.body.drop-cap>p:first-of-type:first-letter {
    float: left;
    font-size: 3em;
    font-weight: bold;
    padding: 2px;
    margin-top: 2px;
    margin-right: 6px;
    line-height: .67em
}

@media only screen and (min-width: 768px) {
    .block.news-content .news-body .content>.body.drop-cap>p:first-of-type:first-letter {
        font-size: 4.6em;
        margin-right: 10px
    }
}

.block.news-content .news-body .content>.body .block {
    margin: 30px 0;
    padding: 0
}

.block.news-content .news-body .content>.body .block:last-child {
    margin-bottom: 0
}

.block.news-content .news-body .content>.body .block.image {
    padding: 0
}

.block.news-content .news-body .content>.body .block.image .image-wrapper {
    margin-bottom: 30px
}

.block.news-content .news-body .content>.body .block.image .image-wrapper div {
    margin-bottom: 10px
}

.block.news-content .news-body .content>.body .block.marketo>.row {
    height: auto
}

.block.news-content .news-body .content>.body .block.marketo .marketo-description h2 {
    background: none;
    padding-left: 0
}

.block.news-content .news-body .content>.body .block.marketo .marketo-content {
    background: transparent;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    min-height: 100px
}

@media only screen and (min-width: 992px) {
    .block.news-content .news-body .content>.body .block.marketo .marketo-content {
        padding: 0 65px 0 0
    }
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: #fff;
    background-color: #cf0722;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button.active {
    color: #fff;
    background-color: #ee0e2c
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form button:focus {
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button {
    color: #2d2d2d;
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.light-orange-accents .marketo-snippet form button.active {
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button {
    color: #2d2d2d;
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.dark-orange-accents .marketo-snippet form button.active {
    background-color: #CCCCCC
}

.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button {
    color: #fff;
    background-color: #cf0722
}

.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.red-accents .marketo-snippet form button.active {
    background-color: #f40828
}

.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button {
    color: #fff;
    background-color: #ce0058
}

.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.rubine-accents .marketo-snippet form button.active {
    background-color: #f40068
}

.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button {
    color: #fff;
    background-color: #a6192e
}

.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.berry-accents .marketo-snippet form button.active {
    background-color: #c71e37
}

.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button {
    color: #fff;
    background-color: #6b3529
}

.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.chocolate-accents .marketo-snippet form button.active {
    background-color: #874334
}

.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button {
    color: #3d3d3d;
    background-color: #f4f4f4
}

.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button:hover,
.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button:active,
.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button:focus,
.block.news-content .news-body .content>.body .block.marketo div.grey-accents .marketo-snippet form button.active {
    background-color: #fff
}

.block.news-content .news-body .content>.body .block.marketo .marketo-snippet form label {
    color: #3d3d3d
}

.block.news-content .news-body .content>.body .block.marketo .marketo-legal a {
    color: #cf0722
}

.block.news-content .news-body .content>.body .full-width-news-image img {
    width: 100vw;
    max-width: none;
    margin-left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.block.news-content .row.news-extras .divider {
    margin-bottom: 15px
}

.block.news-content .author .title,
.block.news-content .sharing .title {
    text-transform: uppercase
}

.block.news-content .author .title {
    margin-bottom: 5px
}

.block.news-content .author .name,
.block.news-content .author .affiliation {
    margin-bottom: 0
}

.block.news-content .author .name {
    font-weight: bold
}

.block.news-content .author .affiliation {
    font-style: italic
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] {
    padding: 0
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_]:not(:first-child) {
    padding-left: 22px
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_]:not(:last-child) {
    padding-right: 22px;
    border-right-width: 1px;
    border-right-style: solid
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] span {
    background-color: transparent !important
}

.block.news-content .sharing .addtoany_list a[class^=a2a_button_] span path {
    fill: currentColor
}

.block.news-content:not(.rounded-top) .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #8d8d8d
}

.block.news-content:not(.rounded-top) div.light-orange-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content:not(.rounded-top) div.dark-orange-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content:not(.rounded-top) div.red-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #cf0722
}

.block.news-content:not(.rounded-top) div.rubine-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #ce0058
}

.block.news-content:not(.rounded-top) div.berry-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #a6192e
}

.block.news-content:not(.rounded-top) div.chocolate-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #6b3529
}

.block.news-content:not(.rounded-top) div.grey-accents .sidebar .sharing .addtoany_list a[class^=a2a_button_] {
    color: #f4f4f4
}

.block.news-content #share-open {
    color: inherit;
    text-decoration: underline
}

.block.news-content #share-mobile {
    position: absolute;
    top: -20px;
    left: 15px;
    right: 15px;
    z-index: 3;
    padding: 15px;
    color: #3d3d3d;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .175)
}

.block.news-content #share-mobile .addtoany_list {
    display: flex
}

.block.news-content #share-mobile .addtoany_list a[class^=a2a_button_] {
    flex: 1;
    text-align: center;
    padding: 0
}

.block.news-content #share-close {
    color: #fff;
    background-color: #8d8d8d
}

.block.news-content div.light-orange-accents #share-close {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.dark-orange-accents #share-close {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.red-accents #share-close {
    background-color: #cf0722;
    color: #fff
}

.block.news-content div.rubine-accents #share-close {
    background-color: #ce0058;
    color: #fff
}

.block.news-content div.berry-accents #share-close {
    background-color: #a6192e;
    color: #fff
}

.block.news-content div.chocolate-accents #share-close {
    background-color: #6b3529;
    color: #fff
}

.block.news-content div.grey-accents #share-close {
    background-color: #f4f4f4;
    color: #3d3d3d
}

.block.news-content #share-close:hover,
.block.news-content #share-close:active,
.block.news-content #share-close:focus {
    color: #fff;
    background-color: #a0a0a0
}

.block.news-content div.light-orange-accents #share-close:hover,
.block.news-content div.light-orange-accents #share-close:active,
.block.news-content div.light-orange-accents #share-close:focus {
    background-color: #CCCCCC;
    color: #2d2d2d
}

.block.news-content div.dark-orange-accents #share-close:hover,
.block.news-content div.dark-orange-accents #share-close:active,
.block.news-content div.dark-orange-accents #share-close:focus {
    background-color: #ff9245;
    color: #2d2d2d
}

.block.news-content div.red-accents #share-close:hover,
.block.news-content div.red-accents #share-close:active,
.block.news-content div.red-accents #share-close:focus {
    background-color: #f40828;
    color: #fff
}

.block.news-content div.rubine-accents #share-close:hover,
.block.news-content div.rubine-accents #share-close:active,
.block.news-content div.rubine-accents #share-close:focus {
    background-color: #f40068;
    color: #fff
}

.block.news-content div.berry-accents #share-close:hover,
.block.news-content div.berry-accents #share-close:active,
.block.news-content div.berry-accents #share-close:focus {
    background-color: #c71e37;
    color: #fff
}

.block.news-content div.chocolate-accents #share-close:hover,
.block.news-content div.chocolate-accents #share-close:active,
.block.news-content div.chocolate-accents #share-close:focus {
    background-color: #874334;
    color: #fff
}

.block.news-content div.grey-accents #share-close:hover,
.block.news-content div.grey-accents #share-close:active,
.block.news-content div.grey-accents #share-close:focus {
    background-color: #fff;
    color: #3d3d3d
}

.block.news-content #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #8d8d8d
}

.block.news-content div.light-orange-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content div.dark-orange-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #CCCCCC
}

.block.news-content div.red-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #cf0722
}

.block.news-content div.rubine-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #ce0058
}

.block.news-content div.berry-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #a6192e
}

.block.news-content div.chocolate-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #6b3529
}

.block.news-content div.grey-accents #share-mobile .addtoany_list a[class^=a2a_button_] {
    color: #f4f4f4
}

.block.news-content .related .title,
.block.news-content .tags .title {
    text-transform: uppercase;
    margin-bottom: 15px
}

.block.news-content .related-divider,
.block.news-content .tag-divider {
    margin-bottom: 30px
}

.block.news-content .related-divider hr,
.block.news-content .tag-divider hr {
    border-width: 2px
}

.block.news-content .related .news-teaser .item-info {
    margin-top: 0
}

.block.news-content .related .news-teaser .title-border {
    margin-top: 0
}

@media only screen and (max-width: 767px) {
    .block.news-content .related .news-teaser:not(:first-child) {
        margin-top: 30px
    }
}

.block.news-content .tags {
    position: relative;
    overflow-x: hidden
}

@media only screen and (max-width: 991px) {
    .block.news-content .tags {
        margin-bottom: 35px
    }
}

.block.news-content .tags ul {
    margin: 0;
    padding: 0
}

.block.news-content .tags ul li {
    color: #cf0722;
    list-style-type: none;
    float: left;
    margin: 0;
    padding-right: 16px
}

.block.news-content .tags ul li:before {
    content: "|";
    color: #3d3d3d;
    display: inline-block;
    font-size: 100%;
    margin-left: -12px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 4px
}

.block.news-content .boilerplate-container {
    border-top-width: 5px;
    border-top-style: solid;
    background-color: #f4f4f4;
    padding: 30px 15px 40px 15px;
    margin-top: 50px;
    margin-left: -15px;
    margin-right: -15px
}

.block.news-content .boilerplate-container .boilerplate-text {
    line-height: 1.8
}

.block.news-content .boilerplate-container .btn-wrapper {
    color: #cf0722
}

.block.news-content .text-only-layout .news-body .content>.body .full-width-news-image img {
    margin-left: calc(75% + 7.5px)
}

.news-hero-section .news-hero-details {
    margin-top: 15px
}

.news-hero-section .news-hero-details .image-information {
    display: flex
}

.news-hero-section .news-hero-details .image-information span:nth-child(2) {
    margin-left: 10px
}

.news-hero-section .news-hero-details .caption,
.news-hero-section .news-hero-details .credit {
    font-size: .9em
}

.news-hero-section .news-hero-details .credit {
    display: flex;
    align-items: center
}

.news-hero-section .news-hero-details .credit .ucws-icon {
    margin-right: 5px;
    color: silver;
    font-size: inherit
}

.news-header-image-below {
    margin-bottom: 50px
}

.news-header-image-below .news-header {
    margin-bottom: 35px
}

.news-header-image-below .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 991px) {
    .news-header-image-below .sidebar {
        margin-top: 35px
    }
}

.news-header-image-above .news-hero-details {
    margin-bottom: 50px
}

.news-header-image-above .news-hero-details .caption-section {
    display: flex;
    flex-direction: column
}

.news-header-image-above .news-hero-details .caption-section .news-hero-accent-divider {
    order: 2;
    border-top: 10px solid;
    border-top-color: inherit;
    margin-top: 15px;
    margin-bottom: 0
}

.news-header-image-above .news-hero-details .caption-section .image-information {
    order: 1
}

@media only screen and (min-width: 768px) {
    .news-header-image-above .news-hero-details {
        margin-top: 0
    }

    .news-header-image-above .news-hero-details .caption-section .news-hero-accent-divider {
        order: 1;
        margin-top: 0;
        margin-bottom: 15px
    }

    .news-header-image-above .news-hero-details .caption-section .image-information {
        order: 2
    }
}

.news-header-image-above .news-header {
    margin-bottom: 50px
}

.news-header-image-above .news-header .content {
    margin-bottom: 35px
}

.news-header-image-above-container .col-image {
    overflow: hidden
}

.news-header-image-above-container .col-image img {
    display: block;
    position: relative
}

@media only screen and (max-width: 1499px) {
    .news-header-image-above-container .col-image img {
        position: relative;
        left: 50%;
        -ms-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media only screen and (min-width: 1500px) {
    .news-header-image-above-container .col-image img {
        width: 100%;
        height: auto
    }
}

body:not(.user-logged-in) .news-header-image-above-container {
    margin-top: -50px
}

@media only screen and (min-width: 992px) {
    body:not(.user-logged-in) .news-header-image-above-container {
        margin-top: -50px
    }
}

.news-header-side-by-side .news-header {
    margin-bottom: 50px
}

.news-header-side-by-side .news-header .content {
    margin-bottom: 35px
}

.news-header-side-by-side .news-hero-details {
    margin-top: 0
}

.news-header-side-by-side .news-hero-details .image-information {
    margin: 15px 0
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .news-hero-details .news-hero-caption-divider {
        border-bottom: 10px solid;
        border-bottom-color: inherit;
        margin-bottom: 50px
    }
}

.news-header-side-by-side .side-by-side-divider-container {
    margin-top: 50px;
    margin-bottom: 35px
}

.news-header-side-by-side .side-by-side-divider-container .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .side-by-side-divider-container {
        display: none
    }
}

@media only screen and (max-width: 767px) {
    .news-header-side-by-side .image-col {
        margin-top: 35px
    }
}

.news-header-text-only {
    margin-bottom: 50px
}

.news-header-text-only .text-only-divider {
    margin-top: 35px
}

.news-header-text-only .text-only-divider .news-hero-accent-divider {
    border-bottom: 10px solid;
    border-bottom-color: inherit
}

@media only screen and (max-width: 991px) {
    .news-header-text-only .sidebar {
        margin-top: 35px
    }
}

.block.news-banner .col-image {
    overflow: hidden
}

.block.news-banner .col-image img {
    width: 100%;
    display: block;
    position: relative;
    height: auto
}

.block.news-banner .col-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}

.block.news-banner .col-text .cta-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 50px 15px
}

@media only screen and (min-width: 992px) {
    .block.news-banner .col-text .cta-wrapper {
        padding: 50px 15px
    }
}

.block.news-banner .col-text .cta-wrapper .cta-content {
    max-width: 550px
}

.block.news-banner .col-text .cta-wrapper .cta-content> :first-child {
    margin-top: 0
}

.block.news-banner .col-text .cta-wrapper .cta-content> :last-child {
    margin-bottom: 0
}

.block.news-banner .col-text .cta-wrapper .cta-content.light {
    color: #fff
}

.block.news-banner .col-text .cta-wrapper .cta-content.light:not(.no-shadow) {
    text-shadow: 0 0 15px #000
}

.block.news-banner .col-text .cta-wrapper .cta-content.light:not(.no-shadow) .btn {
    box-shadow: 0 0 15px rgba(0, 0, 0, .25);
    text-shadow: none
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark {
    color: #3d3d3d
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark:not(.no-shadow) {
    text-shadow: 0 0 15px #fff
}

.block.news-banner .col-text .cta-wrapper .cta-content.dark:not(.no-shadow) .btn {
    box-shadow: 0 0 15px rgba(255, 255, 255, .25);
    text-shadow: none
}

@media only screen and (max-width: 767px) {
    .block.news-banner .col-text .cta-wrapper .cta-content {
        text-align: left
    }
}

.block.news-banner.last-item {
    margin-bottom: 0
}

.block.news-banner>.row {
    position: relative
}

@media only screen and (max-width: 767px) {
    .block.news-banner>.row .col-text .cta-wrapper {
        justify-content: flex-start;
        align-items: flex-end
    }
}

@media only screen and (min-width: 768px) {
    .block.news-banner>.row.vmiddle .col-text .cta-wrapper {
        align-items: center
    }

    .block.news-banner>.row.hleft .col-text .cta-wrapper {
        justify-content: flex-start
    }
}

.block.news-banner:not(.no-grad).light>.row.hleft:before,
.block.news-banner:not(.no-grad).dark>.row.hleft:before {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

@media only screen and (max-width: 767px) {
    .block.news-banner:not(.no-grad).light>.row:before {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)
    }

    .block.news-banner:not(.no-grad).dark>.row:before {
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.8) 100%)
    }
}

@media only screen and (min-width: 768px) {
    .block.news-banner:not(.no-grad).light>.row.hleft:before {
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.news-banner:not(.no-grad).dark>.row.hleft:before {
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }
}

.news-teaser {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px
}

.news-teaser .image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    width: 90px;
    height: 140px
}

.news-teaser .image-wrapper img {
    width: auto;
    height: 140px
}

.news-teaser .item-info {
    display: flex;
    flex-direction: column;
    width: 100%
}

.news-teaser .item-info .title-spacer {
    margin: 10px 0 32px
}

.news-teaser .item-info .title-border {
    margin: 10px 0 20px;
    width: 30px;
    border-top-width: 2px;
    border-top-style: solid
}

.news-teaser .item-info h4,
.news-teaser .item-info h5 {
    margin: 0 0 10px
}

.news-teaser .item-info h4 {
    flex-grow: 1
}

.news-teaser .item-info p:last-child {
    margin-bottom: 0
}

.news-teaser:not(:first-child)>.image-wrapper,
.news-teaser:not(:first-child)>.image-wrapper+.item-info {
    margin-top: 10px
}

.news-teaser:not(:last-child)>.image-wrapper,
.news-teaser:not(:last-child)>.image-wrapper+.item-info {
    margin-bottom: 7px
}


@media screen and (min-width: 480px) {
    .mtop {
        margin-top: -150px;
    }
}

.mtop {
    margin-top: 40px;
}
.hr_card {
    position: relative;
}

.hr_card .sponsor-title {
    margin-top: 10px;
    margin-bottom: 30px;
}

.hr_card .sponsor-title h5 {
    color: #161515;
}

.hr_card .block {
    margin-bottom: 40px;
}

.hr_card .block .list-inline-item:not(:last-child) {
    margin-right: 15px;
}

@media (max-width: 991px) {
    .hr_card .block .list-inline-item:not(:last-child) {
        margin-right: 17px;
        /* margin-left: 27px; */
    }
}

@media (max-width: 991px) {
    .hr_card .block .list-inline-item:last-child {
        margin-right: 7px;
        /* margin-left: 7px; */
    }
}



.hr_card .block .image-block {
    padding: 15px 0;
    background: #fff;
    width: 374px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid transparent;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
}


@media (max-width: 991px) {
    .hr_card .block .image-block {
        padding: 15px 0;
        background: #fff;
        width: 274px;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid transparent;
        padding: 10px;
        margin-bottom: 10px;
        box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
        -webkit-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 7px -2px 13px 2px rgba(0, 0, 0, 0.51);
    }
}


.hr_card .block .image-block img {
    height: 55px;
}

.hr_card .block .image-block:hover {
    border: 1px solid #161515;
}

.hr_card .sponsor-btn {
    margin-top: 10px;
    margin-bottom: 30px;
}


.hr_card_flex_container {
    display: flex;
    flex-wrap: nowrap;
    background-color: rgb(218, 219, 221);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.hr_card_flex_container .item {
    background-color: #f1f1f1;
    width: 100px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

@media (max-width: 991px) {
    .hr_card_flex_container {
        display:block;
        /* flex-wrap: nowrap; */
        background-color: rgb(218, 219, 221);
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        text-align: left;
    }

}
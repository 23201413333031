.pop_up_box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
}

.pop_up_button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06d85f;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.pop_up_button:hover {
    background: #06d85f;
}

.pop_up_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 5;
}

.pop_up_overlay:target {
    visibility: visible;
    opacity: 1;
}

.pop_up_popup {
    margin: 0px auto;
    padding: 20px;
    top: 30%;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
}

.pop_up_popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.pop_up_popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.pop_up_popup .close:hover {
    color: #06d85f;
}

.pop_up_popup .content {
    max-height: 30%;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .pop_up_box {
        width: 70%;
    }

    .pop_up_popup {
        width: 70%;
    }
}
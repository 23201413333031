ul.tabs {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul.tabs>a li {
    padding: 10px;
    background-color: #f4f4f4;
    color: black;
    border-bottom: 1px solid lightgray;
    align-content: space-between;
}

ul.tabs>a li:hover {
    background-color: lightgray;
}
ul.tabs>a li {
    color: #333333;
    text-decoration: none !important;
    font-weight: normal;

}

ul.tabs>a li.selected {
    background-color: #f4f4f4;
}

.nav-top{
    margin-left: 20px;
}

.icons{
    font-weight: bold;
    font-size: 18px;
}
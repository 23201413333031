.textBox {
    width: 230px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
}

.textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
}


.search-container{
    padding-top: 5px;
    border-bottom: 1px solid #000000;
}

.search-container span{
    margin-top: -5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 12px;
}
.search-card{
    border-bottom: 1px solid #eeeeee;
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-card  .title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: inherit;
}
.search-card  p{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.search-card > .links{
    margin-top: -15px !important;
    color: #0a6aa1;
}




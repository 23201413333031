.information-boxes h3 {
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
}

.information-boxes {
    text-align: center;
    margin-top: 0px;
}

.information-boxes p,
.information-boxes ul {
    color: #FFF;
    text-align: left;
}

.information-boxes h4 {
    font-weight: bold;
    font-size: 2rem;
}

.module {
    position: relative;
    margin-bottom: 20px;
}

.courses-info,
.faculty-info,
.newadmission-info {
    position: relative;
    padding: 30px 30px;
}

.courses-info::after,
.faculty-info::after,
.newadmission-info::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.courses-info {
    background-color: #9c9fa6;
    min-height: 200px
}

.no-bullet {
    margin-left: 0;
    list-style: none;
}

.faculty-info {
    background-color: #63656a;
}

.newadmission-info {
    background-color: #9c9fa6;
}


.coursecard {
    width: 100%;
    height: 150px;
    padding: 0 0 10 10;
    background-color: #9c9fa6;
    border-radius: 5px;
}

.coursedetcard {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
}

.white-text {
    color: white;
}

.textBox {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
}

.textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
}


.finder_bg {
    background: rgb(23, 22, 22);
    background: linear-gradient(90deg, rgba(23, 22, 22, 1) 0%, rgba(120, 120, 120, 1) 18%, rgba(210, 210, 210, 1) 40%, rgba(0, 0, 0, 1) 76%, rgba(87, 83, 79, 1) 100%);

}
.col-image img {
    height: 700px !important;
}

.col-text {
    position: absolute !important;
    top: 400px !important;
}

.r-title {
    font-size: xx-large;
    font-weight: bolder;
}

.rr-title {
    font-size: '16px';
}

.tp-control .fa-arrow-circle-left {
    position: absolute;
    bottom: 300px;
    left: 10px;
    color: white;
    font-size: xx-large;
}

.tp-control .fa-arrow-circle-right {
    position: absolute;
    bottom: 300px;
    right: 10px;
    color: white;
    font-size: xx-large;
}

.mar-top {
    margin-top: 0px !important;
}

.caption_div {
    color: white;
    position: absolute;
    bottom: 2%;
    left: 5%;
    background-color: rgb(71, 71, 71);
    opacity: 0.9;
    padding: 25px;
    border-radius: 5px;
    text-align: left;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 250px;
}

.small_screen_btn {
    display: none;
}

@media screen and (max-width: 450px) {
    .col-image img {
        height: 150px !important;
    }

    .col-text {
        font-size: '12px';
        position: absolute !important;
        margin-top: -150px !important;
    }

    .r-title {
        font-size: small;
        font-weight: bolder;
    }

    .rr-title {
        font-size: '12px';
    }

    .tp-control .fa-arrow-circle-left {
        position: absolute;
        bottom: 300px;
        left: 10px;
        color: white;
        font-size: xx-large;
    }

    .tp-control .fa-arrow-circle-right {
        position: absolute;
        bottom: 300px;
        right: 10px;
        color: white;
        font-size: xx-large;
    }

    .mar-top {
        margin-top: -100px;
        position: relative;
    }

    .small_screen_btn {
        display: block;
        position: absolute;
        bottom: 22%;
        left: 5%;
        z-index: 5;
        background-color: rgb(77, 76, 76);
        color: white;
        padding: 5px;
        border-radius: 5px;
        opacity: 0.9;
    }

    .caption_div {
        display: none;
        color: white;
        position: absolute;
        bottom: 2%;
        left: 5%;
        background-color: rebeccapurple;
        opacity: 0.9;
        padding: 25px;
        border-radius: 5px;
        text-align: left;
        align-items: flex-start;
        margin-left: 50px;
        margin-right: 250px;
    }
}

@media screen and (max-width : 680px) {
    .small_screen_btn {
        display: block;
        position: absolute;
        bottom: 22%;
        left: 5%;
        z-index: 5;
        background-color: rgb(77, 76, 76);
        color: white;
        padding: 5px;
        border-radius: 5px;
        opacity: 0.9;
    }

    .caption_div {
        display: none;
        color: white;
        position: absolute;
        bottom: 2%;
        left: 5%;
        background-color: rebeccapurple;
        opacity: 0.9;
        padding: 25px;
        border-radius: 5px;
        text-align: left;
        align-items: flex-start;
        margin-left: 50px;
        margin-right: 250px;
    }

}

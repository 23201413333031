.block.more-info div.more-info-items {
    padding-left: 0;
    margin: 0 -15px
}

.block.more-info div.more-info-items > .more-info-item {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.more-info div.more-info-items > .more-info-item:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-left {
    min-width: 50px
}

.block.more-info div.more-info-items > .more-info-item .media-left span {
    font-size: 35px;
    line-height: 1em
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item, .layout .layout-9 .block.more-info div.more-info-items > .more-info-item, .layout .layout-8 .block.more-info div.more-info-items > .more-info-item, .layout .layout-6 .block.more-info div.more-info-items > .more-info-item {
        padding-right: 15px;
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: left
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.more-info div.more-info-items.two-items > .more-info-item {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.three-items > .more-info-item {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item:nth-child(4n+1) {
        clear: left
    }
}

.bef-exposed-form .form--inline .form-item {
    float: none;
}

.bef-exposed-form .form--inline > .form-item {
    float: left;
}

.bef-exposed-form .form--inline .bef--secondary {
    clear: left;
}

.block.page-filter .row.header ~ .row.divider hr {
    border-width: 3px
}

.block.page-filter .row.divider hr {
    border-color: #63656A
}

.block.page-filter .row.results ul.results {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.block.page-filter .row.results ul.results li.result {
    overflow: hidden;
    margin: 0 0 10px
}

.block.page-filter .row.results ul.results li.result span {
    display: block;
    font-size: .9em
}

.block.page-filter .row.results ul.results li.result a {
    font-weight: 600;
    padding: 10px;
    float: left;
    width: 100%;
    border-left: 10px solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-image: linear-gradient(to bottom, #ffa300, #63656A) 1;
    background: #f4f4f4
}

@media only screen and (max-width: 767px) {
    .block.page-filter .row.results ul.results {
        margin-top: 25px
    }
}


@media (min-width: 992px) {
    .block.page-filter .row.results ul.results {
        margin-left: -15px;
        margin-right: -15px;
    }

    .block.page-filter .row.results ul.results li.result {
        width: 33.3334%;
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        text-align: center;
        font-size: 19px;
        font-weight: 500;
    }

    .block.page-filter .row.results ul.results li.result a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 160px;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        border-top: 10px solid !important;
        border-left: 0 !important;
        border-image: linear-gradient(
                90deg, #63656A 0%, #cccccc 50%, #999999 100%) 1 !important;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topleft: 0;
        border-top-left-radius: 0;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topright: 0;
        border-top-right-radius: 0;
        -webkit-border-bottom-left-radius: 8px;
        -moz-border-radius-bottomleft: 8px;
        border-bottom-left-radius: 8px;
        -webkit-border-bottom-right-radius: 8px;
        -moz-border-radius-bottomright: 8px;
        border-bottom-right-radius: 8px;
        cursor: pointer;
    }
}



nav.pager {
    text-align: center
}

nav.pager ul.pager__items {
    display: inline-block;
    margin: 0
}

nav.pager ul.pager__items li.pager__item {
    display: inline-block;
    margin: 0
}

nav.pager ul.pager__items li.pager__item:not(:last-child) {
    margin-right: 5px !important
}

nav.pager ul.pager__items li.pager__item > a {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    border: 0;
    color: #fff;
    background-color: #8d8d8d;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

nav.pager ul.pager__items li.pager__item.is-active > a {
    cursor: default
}

nav.pager ul.pager__items li.pager__item.pager__item--next > a, nav.pager ul.pager__items li.pager__item.pager__item--previous > a {
    font-size: 20px;
    line-height: 20px
}

nav.pager ul.pager__items li.pager__item.pager__item--previous > a {
    padding-right: 2px
}

nav.pager ul.pager__items li.pager__item.pager__item--next > a {
    padding-left: 2px
}

nav.pager ul.pager__items li.pager__item.pager__item--ellipsis {
    position: relative;
    top: -2px;
    line-height: 10px;
    font-weight: bold;
    font-size: 38px;
    color: #8d8d8d
}

nav.pager ul.pager__items li.pager__item.pager__item--first, nav.pager ul.pager__items li.pager__item.pager__item--last {
    display: none
}

@media screen and (min-width: 767px) {
    nav.pager {
        text-align: right
    }

    nav.pager ul.pager_items li:not(:last-child) {
        margin-right: 20px !important
    }
}

nav.pager ul.pager__items li.pager__item > a:hover, nav.pager ul.pager__items li.pager__item > a:active, nav.pager ul.pager__items li.pager__item > a:focus {
    color: #fff;
    background-color: #a0a0a0
}

div.light-orange-accents nav.pager ul.pager__items li.pager__item > a:hover, div.light-orange-accents nav.pager ul.pager__items li.pager__item > a:active, div.light-orange-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #ffb126;
    color: #2d2d2d
}

div.dark-orange-accents nav.pager ul.pager__items li.pager__item > a:hover, div.dark-orange-accents nav.pager ul.pager__items li.pager__item > a:active, div.dark-orange-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #ff9245;
    color: #2d2d2d
}

div.red-accents nav.pager ul.pager__items li.pager__item > a:hover, div.red-accents nav.pager ul.pager__items li.pager__item > a:active, div.red-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #63656A;
    color: #fff
}

div.rubine-accents nav.pager ul.pager__items li.pager__item > a:hover, div.rubine-accents nav.pager ul.pager__items li.pager__item > a:active, div.rubine-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #63656A;
    color: #fff
}

div.berry-accents nav.pager ul.pager__items li.pager__item > a:hover, div.berry-accents nav.pager ul.pager__items li.pager__item > a:active, div.berry-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #63656A;
    color: #fff
}

div.chocolate-accents nav.pager ul.pager__items li.pager__item > a:hover, div.chocolate-accents nav.pager ul.pager__items li.pager__item > a:active, div.chocolate-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #874334;
    color: #fff
}

div.grey-accents nav.pager ul.pager__items li.pager__item > a:hover, div.grey-accents nav.pager ul.pager__items li.pager__item > a:active, div.grey-accents nav.pager ul.pager__items li.pager__item > a:focus {
    background-color: #fff;
    color: #3d3d3d
}

nav.pager ul.pager__items li.pager__item.is-active > a {
    color: #fff;
    background-color: #a0a0a0
}

div.light-orange-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #ffb126;
    color: #2d2d2d
}

div.dark-orange-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #ff9245;
    color: #2d2d2d
}

div.red-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #63656A;
    color: #fff
}

div.rubine-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #63656A;
    color: #fff
}

div.berry-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #63656A;
    color: #fff
}

div.chocolate-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #874334;
    color: #fff
}

div.grey-accents nav.pager ul.pager__items li.pager__item.is-active > a {
    background-color: #fff;
    color: #3d3d3d
}

.block.views-block.pagination-toggle.dropdown-filters form .form-item.js-form-item-items-per-page, .block.views-block.pagination-toggle:not(.dropdown-filters) form {
    display: none
}

.block.views-block.pagination-toggle .pager-section {
    margin-top: 20px
}

.block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper {
    display: inline-flex
}

.block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-all-button, .block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-less-button, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-all-button, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-less-button {
    border-radius: 60px;
    font-size: 14px;
    font-weight: 700;
    padding: 2px 10px;
    color: #fff;
    background-color: #63656A
}

.block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-all-button:hover, .block.views-block.pagination-toggle .pager-section .show-all-btn-wrapper .show-less-button:hover, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-all-button:hover, .block.views-block.pagination-toggle .pager-section .show-less-btn-wrapper .show-less-button:hover {
    color: #fff;
    background-color: #63656A;
    border-color: #63656A !important
}

.block.views-block.pagination-toggle .pager-section .show-less-btn-container {
    text-align: center
}

@media only screen and (min-width: 768px) {
    .block.views-block.pagination-toggle .pager-section .show-less-btn-container {
        text-align: right
    }
}

@-webkit-keyframes bs-notify-fadeOut {
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes bs-notify-fadeOut {
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bs-notify-fadeOut {
    0% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
    }
}

select.bs-select-hidden, .bootstrap-select > select.bs-select-hidden, select.selectpicker {
    display: none !important;
}

.bootstrap-select {
    width: 220px \0;
    vertical-align: middle;
}

.bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.bootstrap-select > .dropdown-toggle:after {
    margin-top: -1px;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
    color: #999;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
    color: rgba(255, 255, 255, 0.5);
}

.bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;
}

.bootstrap-select > select.mobile-device {
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
    z-index: 2 !important;
}

.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle, .bootstrap-select.is-invalid .dropdown-toggle, .was-validated .bootstrap-select select:invalid + .dropdown-toggle {
    border-color: #63656A;
}

.bootstrap-select.is-valid .dropdown-toggle, .was-validated .bootstrap-select select:valid + .dropdown-toggle {
    border-color: #28a745;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 335px;
    max-width: 550px;
}

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle, .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
    height: auto;
}

:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
    float: none;
    z-index: auto;
}

.form-inline .bootstrap-select, .form-inline .bootstrap-select.form-control:not([class*="col-"]) {
    width: auto;
}

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
}

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right, .row .bootstrap-select[class*="col-"].dropdown-menu-right {
    float: right;
}

.form-inline .bootstrap-select, .form-horizontal .bootstrap-select, .form-group .bootstrap-select {
    margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control, .form-group-sm .bootstrap-select.form-control {
    padding: 0;
}

.form-group-lg .bootstrap-select.form-control .dropdown-toggle, .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle, .bootstrap-select.form-control-lg .dropdown-toggle {
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
    padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
    padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
    width: 100%;
}

.bootstrap-select.disabled, .bootstrap-select > .disabled {
    cursor: not-allowed;
}

.bootstrap-select.disabled:focus, .bootstrap-select > .disabled:focus {
    outline: none !important;
}

.bootstrap-select.bs-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 0 !important;
    padding: 0 !important;
}

.bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060;
}

.bootstrap-select .dropdown-toggle .filter-option {
    position: static;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.bs3.bootstrap-select .dropdown-toggle .filter-option {
    padding-right: inherit;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
    position: absolute;
    padding-top: inherit;
    padding-bottom: inherit;
    padding-left: inherit;
    float: none;
}

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
    padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
}

.bootstrap-select .dropdown-toggle .filter-expand {
    width: 0 !important;
    float: left;
    opacity: 0 !important;
    overflow: hidden;
}

.bootstrap-select .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
    border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
    width: 100%;
}

.bootstrap-select .dropdown-menu {
    min-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important;
}

.bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.bootstrap-select .dropdown-menu li {
    position: relative;
}

.bootstrap-select .dropdown-menu li.active small {
    color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-select .dropdown-menu li.disabled a {
    cursor: not-allowed;
}

.bootstrap-select .dropdown-menu li a {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bootstrap-select .dropdown-menu li a.opt {
    position: relative;
    padding-left: 2.25em;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
    display: none;
}

.bootstrap-select .dropdown-menu li a span.text {
    display: inline-block;
}

.bootstrap-select .dropdown-menu li small {
    padding-left: 0.5em;
}

.bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-select .dropdown-menu .notify.fadeOut {
    -webkit-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
    -o-animation: 300ms linear 750ms forwards bs-notify-fadeOut;
    animation: 300ms linear 750ms forwards bs-notify-fadeOut;
}

.bootstrap-select .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
    position: static;
    display: inline;
    padding: 0;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner, .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
    display: inline;
}

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
    content: '\00a0';
}

.bootstrap-select.fit-width .dropdown-toggle .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 5px;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select .bs-ok-default:after {
    content: '';
    display: block;
    width: 0.5em;
    height: 1em;
    border-style: solid;
    border-width: 0 0.26em 0.26em 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
    z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204, 204, 204, 0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
    bottom: auto;
    top: -4px;
    border-top: 7px solid rgba(204, 204, 204, 0.2);
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
    bottom: auto;
    top: -4px;
    border-top: 6px solid white;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
    display: block;
}

.bs-searchbox, .bs-actionsbox, .bs-donebutton {
    padding: 4px 8px;
}

.bs-actionsbox {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
    width: 50%;
}

.bs-donebutton {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-donebutton .btn-group button {
    width: 100%;
}

.bs-searchbox + .bs-actionsbox {
    padding: 0 8px 4px;
}

.bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
}

.block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default {
    padding: 0
}

.block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default:hover, .block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default:active, .block.views-block.dropdown-filters .container form .bootstrap-select button.btn.btn-default:focus {
    color: #3d3d3d;
    background-color: #fff
}

.block.views-block.dropdown-filters .container form .bootstrap-select .selectpicker.error + .dropdown-toggle {
    color: #63656A;
    border-color: #63656A
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle {
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 600;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid silver;
    display: flex
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .filter-option {
    position: relative;
    padding: 8px 10px 8px 20px;
    flex: 0 1 auto
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .filter-option, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .filter-option * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret {
    display: inline-block;
    height: 40px;
    width: 48px;
    text-align: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #fff;
    background-color: #63656A;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret::before {
    display: block;
    content: "î¤…";
    font-family: ucalgary;
    font-size: 24px;
    line-height: 40px;
    transition: transform 500ms
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret .caret {
    display: none
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret:hover, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret:active, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret:focus {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle:active, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters .container form .bootstrap-select.open .dropdown-toggle {
    background-color: #fff
}

.block.views-block.dropdown-filters .container form .bootstrap-select.open .dropdown-toggle .bs-caret::before {
    transform: rotateX(180deg)
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu {
    padding: 0;
    border-width: 2px;
    border-color: silver;
    z-index: 1968
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu .dropdown-header {
    font-weight: 700;
    font-size: 16px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li {
    margin: 0
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li[class^=optgroup-] {
    list-style: circle;
    margin-left: 35px;
    padding-left: 0
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a {
    padding: 10px 20px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a:hover {
    font-weight: 600
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a.active, .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a.selected {
    color: #fff;
    background-color: #8d8d8d;
    font-weight: 600
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a.opt {
    padding-left: 5px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a span.check-mark {
    top: 15px;
    font-size: .75em
}

.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li.no-results {
    padding: 3px 6px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .bs-searchbox {
    margin-top: 4px;
    margin-bottom: 4px
}

.block.views-block.dropdown-filters .container form .bootstrap-select .bs-searchbox > .form-control:focus {
    border-width: 2px;
    border-color: silver;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none
}

@media only screen and (max-width: 767px) {
    .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu {
        max-width: 100%;
        min-width: auto !important
    }

    .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-menu li a span.text {
        display: inline
    }
}

.block.views-block.dropdown-filters .container form input[type=date], .block.views-block.dropdown-filters .container form input[type=datetime-local], .block.views-block.dropdown-filters .container form input[type=email], .block.views-block.dropdown-filters .container form input[type=month], .block.views-block.dropdown-filters .container form input[type=number], .block.views-block.dropdown-filters .container form input[type=password], .block.views-block.dropdown-filters .container form input[type=search], .block.views-block.dropdown-filters .container form input[type=tel], .block.views-block.dropdown-filters .container form input[type=text], .block.views-block.dropdown-filters .container form input[type=time], .block.views-block.dropdown-filters .container form input[type=url], .block.views-block.dropdown-filters .container form input[type=week], .block.views-block.dropdown-filters .container form textarea, .block.views-block.dropdown-filters .container form select {
    padding: 8px 10px;
    border-radius: 5px;
    border: 2px solid silver;
    width: 100%;
    max-width: 555px;
    font-size: 16px;
    height: auto
}

.block.views-block.dropdown-filters .container form input[type=date]:focus, .block.views-block.dropdown-filters .container form input[type=datetime-local]:focus, .block.views-block.dropdown-filters .container form input[type=email]:focus, .block.views-block.dropdown-filters .container form input[type=month]:focus, .block.views-block.dropdown-filters .container form input[type=number]:focus, .block.views-block.dropdown-filters .container form input[type=password]:focus, .block.views-block.dropdown-filters .container form input[type=search]:focus, .block.views-block.dropdown-filters .container form input[type=tel]:focus, .block.views-block.dropdown-filters .container form input[type=text]:focus, .block.views-block.dropdown-filters .container form input[type=time]:focus, .block.views-block.dropdown-filters .container form input[type=url]:focus, .block.views-block.dropdown-filters .container form input[type=week]:focus, .block.views-block.dropdown-filters .container form textarea:focus, .block.views-block.dropdown-filters .container form select:focus {
    outline: none;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters .container form input[type=date].error, .block.views-block.dropdown-filters .container form input[type=datetime-local].error, .block.views-block.dropdown-filters .container form input[type=email].error, .block.views-block.dropdown-filters .container form input[type=month].error, .block.views-block.dropdown-filters .container form input[type=number].error, .block.views-block.dropdown-filters .container form input[type=password].error, .block.views-block.dropdown-filters .container form input[type=search].error, .block.views-block.dropdown-filters .container form input[type=tel].error, .block.views-block.dropdown-filters .container form input[type=text].error, .block.views-block.dropdown-filters .container form input[type=time].error, .block.views-block.dropdown-filters .container form input[type=url].error, .block.views-block.dropdown-filters .container form input[type=week].error, .block.views-block.dropdown-filters .container form textarea.error, .block.views-block.dropdown-filters .container form select.error {
    border-color: #63656A;
    background-color: #f5ccd2
}

.block.views-block.dropdown-filters div.light-orange-accents .container form input[type=date], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=email], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=month], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=number], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=password], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=search], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=tel], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=text], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=time], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=url], .block.views-block.dropdown-filters div.light-orange-accents .container form input[type=week], .block.views-block.dropdown-filters div.light-orange-accents .container form textarea, .block.views-block.dropdown-filters div.light-orange-accents .container form select {
    border: 2px solid #ffa300
}

.block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=date], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=email], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=month], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=number], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=password], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=search], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=tel], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=text], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=time], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=url], .block.views-block.dropdown-filters div.dark-orange-accents .container form input[type=week], .block.views-block.dropdown-filters div.dark-orange-accents .container form textarea, .block.views-block.dropdown-filters div.dark-orange-accents .container form select {
    border: 2px solid #ff7b1f
}

.block.views-block.dropdown-filters div.red-accents .container form input[type=date], .block.views-block.dropdown-filters div.red-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.red-accents .container form input[type=email], .block.views-block.dropdown-filters div.red-accents .container form input[type=month], .block.views-block.dropdown-filters div.red-accents .container form input[type=number], .block.views-block.dropdown-filters div.red-accents .container form input[type=password], .block.views-block.dropdown-filters div.red-accents .container form input[type=search], .block.views-block.dropdown-filters div.red-accents .container form input[type=tel], .block.views-block.dropdown-filters div.red-accents .container form input[type=text], .block.views-block.dropdown-filters div.red-accents .container form input[type=time], .block.views-block.dropdown-filters div.red-accents .container form input[type=url], .block.views-block.dropdown-filters div.red-accents .container form input[type=week], .block.views-block.dropdown-filters div.red-accents .container form textarea, .block.views-block.dropdown-filters div.red-accents .container form select {
    border: 2px solid #63656A
}

.block.views-block.dropdown-filters div.rubine-accents .container form input[type=date], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=email], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=month], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=number], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=password], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=search], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=tel], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=text], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=time], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=url], .block.views-block.dropdown-filters div.rubine-accents .container form input[type=week], .block.views-block.dropdown-filters div.rubine-accents .container form textarea, .block.views-block.dropdown-filters div.rubine-accents .container form select {
    border: 2px solid #63656A
}

.block.views-block.dropdown-filters div.berry-accents .container form input[type=date], .block.views-block.dropdown-filters div.berry-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.berry-accents .container form input[type=email], .block.views-block.dropdown-filters div.berry-accents .container form input[type=month], .block.views-block.dropdown-filters div.berry-accents .container form input[type=number], .block.views-block.dropdown-filters div.berry-accents .container form input[type=password], .block.views-block.dropdown-filters div.berry-accents .container form input[type=search], .block.views-block.dropdown-filters div.berry-accents .container form input[type=tel], .block.views-block.dropdown-filters div.berry-accents .container form input[type=text], .block.views-block.dropdown-filters div.berry-accents .container form input[type=time], .block.views-block.dropdown-filters div.berry-accents .container form input[type=url], .block.views-block.dropdown-filters div.berry-accents .container form input[type=week], .block.views-block.dropdown-filters div.berry-accents .container form textarea, .block.views-block.dropdown-filters div.berry-accents .container form select {
    border: 2px solid #63656A
}

.block.views-block.dropdown-filters div.chocolate-accents .container form input[type=date], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=email], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=month], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=number], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=password], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=search], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=tel], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=text], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=time], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=url], .block.views-block.dropdown-filters div.chocolate-accents .container form input[type=week], .block.views-block.dropdown-filters div.chocolate-accents .container form textarea, .block.views-block.dropdown-filters div.chocolate-accents .container form select {
    border: 2px solid #6b3529
}

.block.views-block.dropdown-filters div.grey-accents .container form input[type=date], .block.views-block.dropdown-filters div.grey-accents .container form input[type=datetime-local], .block.views-block.dropdown-filters div.grey-accents .container form input[type=email], .block.views-block.dropdown-filters div.grey-accents .container form input[type=month], .block.views-block.dropdown-filters div.grey-accents .container form input[type=number], .block.views-block.dropdown-filters div.grey-accents .container form input[type=password], .block.views-block.dropdown-filters div.grey-accents .container form input[type=search], .block.views-block.dropdown-filters div.grey-accents .container form input[type=tel], .block.views-block.dropdown-filters div.grey-accents .container form input[type=text], .block.views-block.dropdown-filters div.grey-accents .container form input[type=time], .block.views-block.dropdown-filters div.grey-accents .container form input[type=url], .block.views-block.dropdown-filters div.grey-accents .container form input[type=week], .block.views-block.dropdown-filters div.grey-accents .container form textarea, .block.views-block.dropdown-filters div.grey-accents .container form select {
    border: 2px solid #f4f4f4
}

.block.views-block.dropdown-filters .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: #fff;
    background-color: #63656A;
    border-color: transparent !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.views-block.dropdown-filters .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus {
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #2d2d2d;
    background-color: #ffa300
}

.block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.light-orange-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #ffb126
}

.block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #2d2d2d;
    background-color: #ff7b1f
}

.block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.dark-orange-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #ff9245
}

.block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.red-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #63656A
}

.block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.rubine-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #63656A
}

.block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #63656A
}

.block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.berry-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #63656A
}

.block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #fff;
    background-color: #6b3529
}

.block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.chocolate-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #874334
}

.block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button), .block.views-block.dropdown-filters .button.submit-button {
    color: #3d3d3d;
    background-color: #f4f4f4
}

.block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button):hover, .block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button):active, .block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button):focus, .block.views-block.dropdown-filters div.grey-accents .container form .button:not(.reset-button).active, .block.views-block.dropdown-filters .button.submit-button:hover, .block.views-block.dropdown-filters .button.submit-button:active, .block.views-block.dropdown-filters .button.submit-button:focus, .block.views-block.dropdown-filters .button.submit-button.active {
    background-color: #fff
}

.block.views-block.dropdown-filters .container form .button.reset-button {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 42px;
    height: 44px;
    color: inherit;
    background-color: transparent;
    border-color: currentColor !important;
    border-style: solid;
    border-width: 2px;
    border-radius: 50px;
    transition: color 500ms ease, background-color 500ms ease, border-color 500ms ease
}

.block.views-block.dropdown-filters .container form .button.reset-button:hover, .block.views-block.dropdown-filters .container form .button.reset-button:active, .block.views-block.dropdown-filters .container form .button.reset-button:focus, .block.views-block.dropdown-filters .container form .button.reset-button.active {
    color: #fff;
    background-color: #63656A;
    border-color: #63656A !important
}

.block.views-block.dropdown-filters .container form .button.reset-button:active, .block.views-block.dropdown-filters .container form .button.reset-button:focus {
    border-width: 0;
    padding-left: 44px;
    padding-right: 44px;
    box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25);
    outline: none;
    border-color: #8d8d8d
}

.block.views-block.dropdown-filters form {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px
}

.block.views-block.dropdown-filters .form-item.js-form-type-textfield label, .block.views-block.dropdown-filters .form-item.js-form-type-select label {
    display: block
}

.block.views-block.dropdown-filters .form-item.js-form-type-textfield {
    order: 100
}

.block.views-block.dropdown-filters .form-item.js-form-type-select select {
    visibility: hidden
}

.block.views-block.dropdown-filters .form-item.js-form-type-select div.bootstrap-select {
    width: 100%
}

.block.views-block.dropdown-filters .form-actions {
    order: 101;
    flex-basis: 100%;
    margin-left: 15px;
    margin-right: 15px
}

.block.views-block.dropdown-filters .form-actions input.button {
    margin-bottom: 30px;
    margin-top: 20px
}

.block.views-block.dropdown-filters .form-actions input.button:not(:last-child) {
    margin-right: 15px
}

@media only screen and (max-width: 767px) {
    .block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
        width: 100%
    }

    .block.views-block.dropdown-filters .form-item.js-form-type-select div.bootstrap-select {
        width: 100% !important
    }
}

@media only screen and (min-width: 768px)and (max-width: 991px) {
    .block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
        width: calc(50% - 30px)
    }
}

@media only screen and (min-width: 992px) {
    .block.views-block.dropdown-filters .form-item.js-form-type-textfield, .block.views-block.dropdown-filters .form-item.js-form-type-select {
        width: calc(25% - 30px)
    }

    .block.views-block.dropdown-filters .form-actions input.button {
        margin-bottom: 60px
    }
}

.block.views-block.keyword-filters .row.search-bubbles {
    margin-left: 0;
    margin-right: 0
}

.block.views-block.keyword-filters .row.search-bubbles p {
    display: inline-block;
    margin: 0 10px 0 0
}

.block.views-block.keyword-filters .row.search-bubbles .search-bubble {
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 5px 35px 5px 15px;
    border-radius: 15px;
    background-color: #ffa300;
    color: #2d2d2d;
    cursor: default
}

.block.views-block.keyword-filters .row.search-bubbles .search-bubble .remove-bubble-icon {
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    line-height: 16px;
    cursor: pointer
}


.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret::before {
    display: block;
    content: "";
    font-family: ucalgary;
    font-size: 24px;
    line-height: 40px;
    transition: transform 500ms ease 0s;
}
.block.views-block.dropdown-filters .container form .bootstrap-select .dropdown-toggle .bs-caret .caret {
    display: none;
}
.bootstrap-select .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}
.btn .caret {
    margin-left: 0;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}


.cardImage{

}
.cardDetailsContainer{
    margin:'10px'
}
.cardDetails{
    background-color:  #f4f4f4;
    top:-50px;
    border-radius:5px;
    padding: 15px
}
.applyButton{
    margin-top: -40px;
}

.padd{
    margin-left: 20%;
    margin-right: 20%;
}
.textBox {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
    display: flex;
  }
  
  .textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid gray;
  }

.link{
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
}

.alert {
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 12px;
    font-size: 16px;
  }
  
  .alert.alert-success {
    background-color: rgba(227, 253, 235, 1);
    border-color: rgba(38, 179, 3, 1);
    color: rgba(60, 118, 61, 1);
  }
  
  .alert.alert-info {
    background-color: rgba(217, 237, 247, 1);
    color: rgba(49, 112, 143, 1);
    border-color: rgba(126, 182, 193, 1);
  }
  
  
  .alert.alert-warning {
    background-color: rgba(252, 248, 227, 1);
    border-color: rgba(177, 161, 129, 1);
    color: rgba(138, 109, 59, 1);
  }
  
  .alert.alert-danger {
    background-color: rgba(248, 215, 218, 1);
    border-color: rgba(220, 53, 69, 1);
    color: rgba(114, 28, 36,1);
  }
.block.image-text .min-height-fix {
    display: flex
}

.block.image-text .rounded-box {
    display: flex;
    flex-direction: column;
    width: 100%
}

.block.image-text .rounded-box .sidekick-image {
    background-image: none
}

.block.image-text .rounded-box .sidekick-text {
    padding: 25px;
    text-align: left
}

.block.image-text .rounded-box .sidekick-text h2{
    margin-top: 5px !important;
}

.block.image-text .rounded-box .sidekick-text > *:first-child {
    margin-top: 0
}

.block.image-text .rounded-box .sidekick-text > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .block.image-text .rounded-box {
        flex-direction: row;
        align-items: center;
    }

    .block.image-text .rounded-box .sidekick-image, .block.image-text .rounded-box .sidekick-text {
        width: 50%
    }

    .block.image-text .rounded-box .sidekick-image {
        height: auto;
        align-self: stretch;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat
    }

    .block.image-text .rounded-box .sidekick-image img {
        display: none
    }

    .block.image-text .rounded-box .sidekick-text {
        padding: 40px;
        text-align: center
    }

    .block.image-text.image-right .sidekick-text {
        order: -1
    }
}

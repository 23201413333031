.block.related-content > .row {
    background-color: rgba(255, 255, 255, .5)
}

.block.related-content.rounded-block-above > div.row:first-child {
    padding-top: 0 !important
}

.block.related-content.rounded-block-below > div.row:last-child {
    padding-bottom: 0 !important
}

.block.related-content .chicklet {
    padding: 0
}

.block.related-content .chicklet .chicklet-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px
}

.block.related-content .chicklet .chicklet-image p.h2 {
    display: none
}

.block.related-content .chicklet .chicklet-content {
    position: relative;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer
}

.block.related-content .chicklet .chicklet-content .content-wrapper {
    height: 100%;
    padding: 25px
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :first-child {
    margin-top: 0
}

.block.related-content .chicklet .chicklet-content .content-wrapper > :last-child {
    margin-bottom: 5px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child) {
    margin-bottom: 20px
}

.block.related-content .chicklet .chicklet-content .content-wrapper p.h2:not(:last-child):after {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: inherit
}

@media only screen and (max-width: 767px) {
    .block.related-content .chicklet .chicklet-content {
        margin-top: -8px
    }

    .block.related-content .chicklet:not(:last-child) .chicklet-content {
        margin-bottom: -8px
    }
}

@media only screen and (max-width: 767px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-image {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 0;
        padding-bottom: 20px
    }
}

@media only screen and (min-width: 768px) {
    .block.related-content .chicklet {
        height: 400px
    }

    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.related-content .chicklet .chicklet-image {
        height: 100%
    }

    .block.related-content .chicklet .chicklet-content {
        z-index: 2
    }

    .block.related-content .chicklet .chicklet-content p.link {
        position: absolute;
        left: 25px;
        bottom: 25px
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content .chicklet .chicklet-image, .block.related-content .chicklet .chicklet-content {
        width: 50%;
        border-radius: 0
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-image {
        left: auto
    }

    .block.related-content .chicklet:nth-child(even) .chicklet-content {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-image {
        right: auto
    }

    .block.related-content .chicklet:nth-child(odd) .chicklet-content {
        left: auto
    }
}

@media only screen and (min-width: 768px)and (max-width: 1199px) {
    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) {
        height: 420px
    }

    .block.related-content.rounded-block-above > div.row > .chicklet:nth-child(1) .chicklet-content {
        margin-top: 20px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1), .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) {
        height: 420px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet:nth-last-child(1) .chicklet-content, .block.related-content.rounded-block-below.contextual-region > div.row > .chicklet:nth-last-child(2) .chicklet-content {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content .chicklet .chicklet-image img {
        cursor: pointer
    }

    .block.related-content .chicklet .chicklet-image::before {
        content: "";
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.related-content .chicklet .chicklet-image p.h2 {
        display: block;
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        margin: 0 0 40px;
        z-index: 2;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 10px #000
    }

    .block.related-content .chicklet .chicklet-content {
        top: auto;
        right: 25px;
        bottom: 25px;
        left: 25px;
        width: auto;
        height: 15px;
        transition: height .5s ease-in-out !important;
        overflow: hidden
    }

    .block.related-content .chicklet .chicklet-content .content-wrapper {
        position: relative;
        height: 350px
    }

    .block.related-content .chicklet:hover .chicklet-content, .block.related-content .chicklet:active .chicklet-content, .block.related-content .chicklet:focus .chicklet-content {
        height: 350px
    }
}

@media only screen and (min-width: 1200px) {
    .block.related-content.rounded-block-above > div.row > .chicklet, .block.related-content.rounded-block-below > div.row > .chicklet {
        height: 450px
    }

    .block.related-content.rounded-block-above.rounded-block-below > div.row > .chicklet {
        height: 500px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-image p.h2 {
        bottom: 75px
    }

    .block.related-content.rounded-block-below > div.row > .chicklet .chicklet-content {
        bottom: 75px
    }
}

.textBox_t {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .textBox_t:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }

  /* ///////////// */

.pg_container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: grey;
    border-style: solid;

}

.pg_page-link {
    height: 30px;
    background-color: grey;
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    display: block;
    transition: 400ms ease;
    color: white;
    letter-spacing: 0.1em;
}

.pg_active {
    height: 30px;
    background-color: rgb(0, 0, 0);
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    color: white;
}
ul {
    list-style-type: none;

}


@media only screen and (max-width: 600px) {
    .items-list {
        grid-template-columns: repeat(2, 1fr);
    }
}



.textBox {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }
  
  
  .st_card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
  }
  
  .st_card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  
  .st_container {
    padding: 2px 16px;
  }
  
  
  
  .fv_container {
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    width: 500px;
    margin-bottom: 22px;
  }
  
  @media screen and (max-width: 490px) {
    .fv_container {
      box-shadow: 0 15px 30px 1px grey;
      background: rgba(255, 255, 255, 0.90);
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      height: 250px;
      width: 350px;
      margin-bottom: 22px;
    }
  
    .fv_product-image {
      display: none;
    }
  
    .fv_product-details {
  
      float: left;
      width: 100%;
  
    }
  
  }
  
  @media screen and (max-width: 360px) {
    .fv_container {
      box-shadow: 0 15px 30px 1px grey;
      background: rgba(255, 255, 255, 0.90);
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      height: 250px;
      width: 100%;
      margin-bottom: 22px;
    }
  
    .fv_product-image {
      display: none;
    }
  
    .fv_product-details {
  
      float: left;
      width: 100%;
    }
  }
  
  
  /* @media screen and (max-width: 760px) {
    .fv_container {
      box-shadow: 0 15px 30px 1px grey;
      background: rgba(255, 255, 255, 0.90);
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      height: 250px;
      width: 650px;
      margin-bottom: 22px;
    }
    
  }
   */
  
  
  
  .fv_product-details {
    position: relative;
    text-align: left;
    overflow: hidden;
    padding: 20px;
  
    height: 100%;
    float: left;
    width: 50%;
    max-width: 50%;
  
  }
  
  
  .fv_container .product-details>p {
    font-family: 'EB Garamond', serif;
    text-align: center;
    font-size: 18px;
    color: #7d7d7d;
  
  }
  
  .fv_control {
    position: absolute;
    bottom: 5%;
    left: 55.8%;
    color: white;
  
  }
  
  .fv_btn {
  
    transform: translateY(0px);
    transition: 0.3s linear;
    background: #595a5a;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    color: #eee;
    padding: 10px;
    margin: 0;
  
  }
  
  .fv_btn:hover {
    transform: translateY(-6px);
    background: #d5d6d8;
    color: black
  }
  
  .fv_btn span {
    font-family: 'Farsan', cursive;
    transition: transform 0.3s;
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    margin: 0;
  
  }
  
  .fv_btn .fv_price,
  .fv_shopping-cart {
    background: #333;
    border: 0;
    margin: 0;
  }
  
  .fv_btn .fv_price {
    transform: translateX(-10%);
    padding-right: 15px;
  }
  
  .fv_btn .fv_shopping-cart {
    transform: translateX(-100%);
    position: absolute;
    background: #333;
    z-index: 1;
    left: 0;
    top: 0;
  }
  
  .fv_btn .fv_buy {
    z-index: 3;
    font-weight: bolder;
    color: white;
  }
  
  .fv_btn:hover .fv_price {
    transform: translateX(-110%);
  }
  
  .fv_btn:hover .fv_shopping-cart {
    transform: translateX(0%);
  }
  
  
  
  .fv_product-image {
    transition: all 0.3s ease-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 100%;
    float: right;
    width: 45%;
    display: inline-block;
  }
  
  .fv_container img {
    width: 100%;
    height: 100%;
  }
  
  .fv_info {
    background: rgba(27, 26, 26, 0.9);
    font-family: 'monospace', monospace;
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    text-align: left;
    font-size: 105%;
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  
  .fv_info h2 {
    text-align: center
  }
  
  .fv_product-image:hover .fv_info {
    transform: translateX(0);
  }
  
  .fv_info ul li {
    transition: 0.3s ease;
  }
  
  .fv_info ul li:hover {
    transform: translateX(50px) scale(1.3);
  }
  
  .fv_product-image:hover img {
    transition: all 0.3s ease-out;
  }
  
  .fv_product-image:hover img {
    transform: scale(1.2, 1.2);
  }
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-research-area,
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-job-title,
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-research-thm,
.block.profile-list.views-block.dropdown-filters .view-output .form-item-field-ucws-profile-unit-dept {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.show_faculty_research_area_filter .form-item-field-ucws-profile-research-area {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.show_job_title_filter .form-item-field-ucws-profile-job-title {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.show_research_theme_filter .form-item-field-ucws-profile-research-thm {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.show_unit_department_filter .form-item-field-ucws-profile-unit-dept {
    display: inline-block
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_job_title .roles .job-title {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_profile_photos .profile-image {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_research_interests .research-areas {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_unit_department .roles .unit-department-name {
    display: none
}

.block.profile-list.views-block.dropdown-filters .view-output.hide_keyword_search .row.filters,
.block.profile-list.views-block.dropdown-filters .view-output.hide_keyword_search .row.filters+.row.divider,
.block.profile-list.views-block.dropdown-filters .view-output.hide_keyword_search .row.search-bubbles {
    display: none
}

.block.profile-list .row.header .icon {
    width: 56px;
    height: 56px;
    margin-top: 6px;
    margin-right: 30px;
    border-image: linear-gradient(90deg, #63656A 0%, #cccccc 50%, #999999 100%) 1 !important;
}

.block.profile-list .row.header .icon .icomoon {
    font-size: 56px
}

.block.profile-list .row.header .text {
    align-self: center
}

.block.profile-list .row.header .text>*:first-child {
    margin-top: 0
}

.block.profile-list .row.header .text>*:last-child {
    margin-bottom: 0
}

.block.profile-list .row.header .text h2:last-child {
    margin-top: 8px
}

.block.profile-list .divider hr {
    border-width: 3px;
    border-image: linear-gradient(90deg, #63656A 0%, #cccccc 50%, #999999 100%) 1 !important;
}

.block.profile-list .divider hr.pager-divider {
    border-width: 1px
}

.block.profile-list .row.profile.view-output>div {
    padding-left: 15px;
    padding-right: 15px
}

.block.profile-list .row.profile.view-output>div .profile-view-container {
    padding-top: 15px;
    padding-bottom: 25px
}

.block.profile-list ol.profile-items-list {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0
}

.block.profile-list ol.profile-items-list>li.profile {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 20px;
    padding-right: 20px;
    width: calc(50% - 20px)
}

.block.profile-list ol.profile-items-list>li.profile:nth-child(-n+2) {
    margin-top: 0
}

.block.profile-list ol.profile-items-list>li.profile:nth-child(odd) {
    clear: none
}

.block.profile-list ol.profile-items-list>li.profile:not(:last-child) {
    border-right: 1px solid #8d8d8d
}

.block.profile-list ol.profile-items-list>li.profile:nth-child(2n) {
    border-right: none
}

.block.profile-list ol.profile-items-list>li.profile:first-child {
    margin-top: 0
}

.block.profile-list ol.profile-items-list>li.profile .profile-image {
    margin-bottom: 15px
}

.block.profile-list ol.profile-items-list>li.profile .profile-image img {
    max-height: 205px
}

.block.profile-list ol.profile-items-list>li.profile .status {
    margin-top: 20px;
    margin-bottom: 5px;
    font-style: italic
}

.block.profile-list ol.profile-items-list>li.profile .status .accepting {
    color: #ffcd00
}

.block.profile-list ol.profile-items-list>li.profile .status .seeking {
    color: #47a67c
}

.block.profile-list ol.profile-items-list>li.profile .status .unavailable:before {
    content: "";
    color: #8d8d8d
}

.block.profile-list ol.profile-items-list>li.profile .roles {
    font-weight: bold;
    margin-bottom: 20px
}

.block.profile-list ol.profile-items-list>li.profile .research-areas {
    margin-bottom: 20px
}

.block.profile-list ol.profile-items-list>li {
    vertical-align: top
}

.hgt {
    max-height: 400px !important;
}

@media screen and (max-width:400px) {
    .hgt {
        max-height: 200px !important;
    }
}


@media only screen and (min-width: 556px) {
    .layout .layout-12 .block.profile-list ol.profile-items-list>li {
        width: calc(33.3% - 20px)
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(-n+3) {
        margin-top: 0
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:not(:last-child) {
        border-right: 1px solid #8d8d8d
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(3n) {
        border-right: none
    }
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.profile-list ol.profile-items-list>li {
        width: calc(25% - 20px)
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:not(:last-child) {
        border-right: 1px solid #8d8d8d
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(4n) {
        border-right: none
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.profile-list ol.profile-items-list>li {
        width: calc(25% - 20px)
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(4n+1) {
        clear: left
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:not(:last-child) {
        border-right: 1px solid #8d8d8d
    }

    .layout .layout-12 .block.profile-list ol.profile-items-list>li:nth-child(4n) {
        border-right: none
    }
}


.video_frame {
    border-style: none;
    width: 100%;
    height: 120px;
}
.wrapper_list {
	/* max-width: 90%; */
	margin: 0 auto;
}

ol_list {
	list-style: none;
	padding: 0;
}

li_list + li_list {
	margin-top: 1rem;
}

li_list {
	display: flex;
	align-items: center;
	gap: 1rem;
	background: aliceblue;
	padding: 1.5rem;
	border-radius: 1rem;
	width: calc(100% - 2rem);
	box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
}

li_list::before {
	counter-increment: list-item;
	content: counter(list-item);
	font-size: 3rem;
	font-weight: 700;
	width: 2em;
	height: 2em;
	background: black;
	flex: 0 0 auto;
	border-radius: 50%;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

li_list:nth-child(even) {
	flex-direction: row-reverse;
	background: lavender;
	margin-right: -2rem;
	margin-left: 2rem;
}



.section-team_rs {
	font-family: "Poppins", sans-serif;
	/* padding: 80px 0; */
}



.section-team_rs .single-person {
	/* margin-top: 20px; */
	padding: 30px;
	background-color: #f2f2f2;
	border-radius: 5px;
    min-height: 300px;
}

.section-team_rs .single-person:hover {
	background: linear-gradient(to right, #606061, #bdbebe);
}

.section-team_rs .single-person .person-image {
    position: relative;
    border-radius: 50%;
    border: 4px dashed transparent;
    transition: padding .3s;
}

.section-team_rs .single-person:hover .person-image {
	padding: 12px;
    border: 4px dashed #fff;
}

.section-team_rs .single-person .person-image img {
	width: 100%;
    border-radius: 50%;
}

.section-team_rs .single-person:hover .speciality {
	color: #fff;
}

.delay-show { 
	animation: showMe 2s forwards;
}

.delay-news-show { 
	animation: showMe 3s forwards;
}
@keyframes showMe{
    100%{
        opacity: 1;
    }
    0%{
        opacity: 0;
    }
}
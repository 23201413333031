.date-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    height: 65px;
    width: 60px;
    padding-top: 12px;
    margin-right: 15px;
    margin-bottom: 1em;
    color: #fff;
    background-image: linear-gradient(#b5b5b5, #6e767d, #42484d);
    font-size: 18px;
}

.event-description span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: lighter;
    text-transform: uppercase;
    font-size: larger;
}

.event-description p {
    font-size: 12px;
}


/* ///////////// */

.pg_container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: grey;
    border-style: solid;

}

.pg_page-link {
    height: 30px;
    background-color: grey;
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    display: block;
    transition: 400ms ease;
    color: white;
    letter-spacing: 0.1em;
}

.pg_active {
    height: 30px;
    background-color: rgb(0, 0, 0);
    border-radius: 2px;
    padding: 5px;
    margin: 5px;
    color: white;
}

ul {
    list-style-type: none;

}


@media only screen and (max-width: 600px) {
    .items-list {
        grid-template-columns: repeat(2, 1fr);
    }
}


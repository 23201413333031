@media only screen and (min-width: 768px) {
    .block.hero-cta.top h1 {
        line-height: 1.05em
    }

    .block.hero-cta.top p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.25em
    }

    .block.hero-cta.top .btn {
        font-size: 18px
    }
}

.block.hero-cta.image-hero-cta.top.inline-bg-image.rounded-block-below > .row {
    padding: 0
}

.block.hero-cta.image-hero-cta.top.inline-bg-image.rounded-block-below .cta-wrapper {
    padding-bottom: 70px
}

@media only screen and (min-width: 1200px) {
    .block.hero-cta.image-hero-cta.top.inline-bg-image.rounded-block-below .cta-wrapper {
        padding-bottom: 100px
    }
}

.block.hero-cta.legacy-image-hero .col-image {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;
    overflow: hidden
}

.block.hero-cta.legacy-image-hero .col-text .cta-content > :first-child {
    margin-top: 0
}

.block.hero-cta.legacy-image-hero .col-text .cta-content > :last-child {
    margin-bottom: 0
}

@media only screen and (max-width: 991px) {
    .block.hero-cta.legacy-image-hero > .row {
        background-size: 0 0;
        background-repeat: no-repeat
    }

    .block.hero-cta.legacy-image-hero.bg-image > [class*=-back].row > .container > .row:first-child {
        padding-top: 0 !important
    }

    .block.hero-cta.legacy-image-hero.bg-image > [class*=-back].row > .container > .row:last-child {
        padding-bottom: 0 !important
    }

    .block.hero-cta.legacy-image-hero.bg-image .col-image img {
        min-width: 500px;
        max-width: calc(100% + 2px);
        position: relative;
        left: 50%;
        -ms-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .block.hero-cta.legacy-image-hero.bg-image .col-text {
        padding-top: 25px;
        padding-bottom: 25px
    }
}

@media only screen and (min-width: 992px) {
    .block.hero-cta.legacy-image-hero {
        height: 600px
    }

    .block.hero-cta.legacy-image-hero > .row {
        height: 100%;
        position: relative;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat
    }

    .block.hero-cta.legacy-image-hero > .row > .container {
        height: 100%
    }

    .block.hero-cta.legacy-image-hero > .row > .container > .row {
        height: 100%;
        padding: 50px 0
    }

    .block.hero-cta.legacy-image-hero > .row > .container > .row::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%
    }

    .block.hero-cta.legacy-image-hero > .row .col-text {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        float: none;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 10px;
        width: calc(100% - 5px)
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        max-width: 550px;
        z-index: 2
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light, .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light a:not(.btn) {
        color: #fff
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light:not(.no-shadow) {
        text-shadow: 0 0 15px #000
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light:not(.no-shadow) .btn {
        box-shadow: 0 0 15px rgba(0, 0, 0, .25);
        text-shadow: none
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.light:not(.no-shadow) .btn:focus {
        box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25), 0 0 15px rgba(0, 0, 0, .25)
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark, .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark a:not(.btn) {
        color: #3d3d3d
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark:not(.no-shadow) {
        text-shadow: 0 0 15px #fff
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark:not(.no-shadow) .btn {
        box-shadow: 0 0 15px rgba(255, 255, 255, .25);
        text-shadow: none
    }

    .block.hero-cta.legacy-image-hero > .row .col-text .cta-content.dark:not(.no-shadow) .btn:focus {
        box-shadow: inset 0px 0px 15px 0px rgba(45, 45, 45, .25), 0 0 15px rgba(255, 255, 255, .25)
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vtop .col-text {
        vertical-align: top
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vmiddle .col-text {
        vertical-align: middle
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vbottom .col-text {
        vertical-align: bottom
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.hleft .col-text {
        text-align: left
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.hcenter .col-text {
        text-align: center
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.hright .col-text {
        text-align: right
    }

    .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vtop.hcenter .col-text .cta-content, .layout .layout-12 .block.hero-cta.legacy-image-hero > .row.vbottom.hcenter .col-text .cta-content {
        max-width: 75%
    }

    .block.hero-cta.legacy-image-hero.light > .row.vtop::before, .block.hero-cta.legacy-image-hero.light > .row.hright::before, .block.hero-cta.legacy-image-hero.light > .row.vbottom::before, .block.hero-cta.legacy-image-hero.light > .row.hleft::before, .block.hero-cta.legacy-image-hero.dark > .row.vtop::before, .block.hero-cta.legacy-image-hero.dark > .row.hright::before, .block.hero-cta.legacy-image-hero.dark > .row.vbottom::before, .block.hero-cta.legacy-image-hero.dark > .row.hleft::before {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

    .block.hero-cta.legacy-image-hero.light > .row.hcenter.vtop::before {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 10%, rgba(0, 0, 0, 0.39) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 10%, rgba(0, 0, 0, 0.39) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 10%, rgba(0, 0, 0, 0.39) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 10%, rgba(0, 0, 0, 0.39) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 10%, rgba(0, 0, 0, 0.39) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.light > .row.hright::before {
        background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.light > .row.hcenter.vbottom::before {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.55) 100%)
    }

    .block.hero-cta.legacy-image-hero.light > .row.hleft::before {
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hcenter.vtop::before {
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 10%, rgba(255, 255, 255, 0.39) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 10%, rgba(255, 255, 255, 0.39) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 10%, rgba(255, 255, 255, 0.39) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 10%, rgba(255, 255, 255, 0.39) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 10%, rgba(255, 255, 255, 0.39) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hright::before {
        background: -moz-linear-gradient(right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hcenter.vbottom::before {
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.55) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.55) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.55) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.55) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.55) 100%)
    }

    .block.hero-cta.legacy-image-hero.dark > .row.hleft::before {
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%)
    }

    .block.hero-cta.legacy-image-hero.no-grad > .row::before {
        background: none !important
    }
}

.block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading {
    padding-top: 20px
}

.block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading .divider hr {
    margin-bottom: 15px;
    border-width: 2px
}

.block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading .divider p {
    font-size: 1em;
    line-height: 1em
}

@media only screen and (min-width: 768px) {
    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading h1, .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading h2 {
        line-height: 1.05em
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.25em
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading .btn {
        font-size: 18px
    }
}

@media only screen and (min-width: 992px) {
    .block.hero-cta.legacy-image-hero.top.news {
        height: 700px
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 0;
        color: #fff;
        text-shadow: 0 0 15px #000
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading hr {
        border-color: #fff !important
    }

    .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading hr, .block.hero-cta.legacy-image-hero.top.news .col-feature-news-heading a.btn.btn-ghost {
        box-shadow: 0 0 15px rgba(0, 0, 0, .25)
    }
}
.font-black{
    color:black;
    text-decoration:none
}
/* {----------} */
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
    /* padding: 8px; */
    cursor: pointer;
    width: 33.3% !important;
    text-align: center !important;
}




.profile_card {
    width: 500px;
    min-height: 100px;
    padding: 20px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
  .profile_card:after {
    content: "";
    display: block;
    width: 100%;
    height: 300px;
    background: rgb(149, 153, 153);
    position: absolute;
    animation: rotatemagic 0.75s cubic-bezier(0.425, 1.04, 0.47, 1.105) 1s both;
  }
  
  .badgescard {
    padding: 10px 20px;
    border-radius: 3px;
    background-color: #ECECEC;
    width: 480px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: -1;
    left: 10px;
    bottom: 10px;
    animation: animainfos 0.5s cubic-bezier(0.425, 1.04, 0.47, 1.105) 0.75s forwards;
  }
  .badgescard span {
    font-size: 1.6em;
    margin: 0px 6px;
    opacity: 0.6;
  }
  
  .firstinfo {
    flex-direction: row;
    z-index: 2;
    position: relative;
  }
  .firstinfo img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
  }
  .firstinfo .profileinfo {
    padding: 0px 20px;
  }
  .firstinfo .profileinfo h1 {
    font-size: 1.8em;
  }
  .firstinfo .profileinfo h3 {
    font-size: 1.2em;
    color: #0c0c0c;
    font-style: italic;
  }
  .firstinfo .profileinfo p.bio {
    padding: 10px 0px;
    color: #5A5A5A;
    line-height: 1.2;
    font-style: initial;
  }

  .profile_content {
    position: relative;
    animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
  }
  
.block.divider hr {
    height: 0;
    border-width: 2px 0 0;
    border-style: solid
}

.block.divider hr.dotted {
    border-width: 3px 0 0;
    border-style: dotted
}

.block.checklist div.checklist-items {
    padding-left: 0;
    margin: 0 -15px;
    counter-reset: checklist
}

.block.checklist div.checklist-items > .checklist-item {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.checklist div.checklist-items > .checklist-item:first-child {
    margin-top: 0
}

.block.checklist div.checklist-items > .checklist-item .media-left {
    min-width: 45px
}

.block.checklist div.checklist-items > .checklist-item .media-left span {
    position: relative;
    top: -3px;
    left: -3px;
    font-size: 35px;
    line-height: 1em
}

.block.checklist div.checklist-items > .checklist-item .media-body h3:before {
    display: block;
    counter-increment: checklist 1;
    content: counter(checklist);
    font-size: 50px;
    font-weight: bold;
    line-height: 35px;
    padding-left: 75px;
    margin-left: -75px;
    margin-bottom: 10px
}

.block.checklist div.checklist-items > .checklist-item .media-body > *:first-child {
    margin-top: 0
}

.block.checklist div.checklist-items > .checklist-item .media-body > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item {
        padding-right: 15px;
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(odd) {
        clear: left
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.checklist div.checklist-items > .checklist-item:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.checklist div.checklist-items.two-items > .checklist-item {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items.three-items > .checklist-item {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items.max-items > .checklist-item {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.checklist div.checklist-items.max-items > .checklist-item:nth-child(4n+1) {
        clear: left
    }
}

[class^="ucws-checklist-"], [class*=" ucws-checklist-"] {
    font-family: 'ucws-checklist' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ucws-checklist-check-square:before {
    content: "\e901";
}

.ucws-checklist-check-circle:before {
    content: "\e900";
}

.ucws-checklist-check-square-solid:before {
    content: "\e834";
}

.ucws-checklist-check-circle-solid:before {
    content: "\e86c";
}

.layout--onecol .layout__region {
    width: 100%;
}

.block.more-info div.more-info-items {
    padding-left: 0;
    margin: 0 -15px
}

.block.more-info div.more-info-items > .more-info-item {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.more-info div.more-info-items > .more-info-item:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-left {
    min-width: 50px
}

.block.more-info div.more-info-items > .more-info-item .media-left span {
    font-size: 35px;
    line-height: 1em
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:first-child {
    margin-top: 0
}

.block.more-info div.more-info-items > .more-info-item .media-body > *:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item, .layout .layout-9 .block.more-info div.more-info-items > .more-info-item, .layout .layout-8 .block.more-info div.more-info-items > .more-info-item, .layout .layout-6 .block.more-info div.more-info-items > .more-info-item {
        padding-right: 15px;
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-9 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-8 .block.more-info div.more-info-items > .more-info-item:nth-child(odd), .layout .layout-6 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: left
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(-n+4) {
        margin-top: 0
    }

    .layout .layout-12 .block.more-info div.more-info-items > .more-info-item:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.more-info div.more-info-items.two-items > .more-info-item {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.three-items > .more-info-item {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.more-info div.more-info-items.max-items > .more-info-item:nth-child(4n+1) {
        clear: left
    }
}

.block.accordion .panel-default {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none
}

.block.accordion .panel-default + .panel {
    margin-top: 15px
}

.block.accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: none
}

.block.accordion .panel-default:last-child .panel-body > *:last-child {
    margin-bottom: 0
}

.block.accordion .container .row.header ol, .block.accordion .container .row.header ul {
    margin-bottom: 15px
}

.block.accordion .container .row.header p ~ ul, .block.accordion .container .row.header p ~ ol, .block.accordion .container .row.header ol ol, .block.accordion .container .row.header ol ul, .block.accordion .container .row.header ul ol, .block.accordion .container .row.header ul ul {
    padding-left: 40px
}

.block.accordion.solid .panel-heading, .block.accordion.border .panel-heading {
    border-radius: 0;
    transition: background-color 500ms ease;
    padding: 10px 5px 10px 15px
}

.block.accordion.solid .panel-heading h4.panel-title a, .block.accordion.border .panel-heading h4.panel-title a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #63656A
}

.block.accordion.solid .panel-heading h4.panel-title a span, .block.accordion.border .panel-heading h4.panel-title a span {
    display: block
}

.block.accordion.solid .panel-heading h4.panel-title a .ucws-icon, .block.accordion.border .panel-heading h4.panel-title a .ucws-icon {
    font-size: 32px;
    transition: transform 500ms;
    color: #63656A
}

.block.accordion.solid .panel-heading.active, .block.accordion.border .panel-heading.active {
    background-color: #63656A
}

.block.accordion.solid .panel-heading.active h4.panel-title a, .block.accordion.border .panel-heading.active h4.panel-title a {
    color: #fff
}

.block.accordion.solid .panel-heading.active h4.panel-title a .ucws-icon, .block.accordion.border .panel-heading.active h4.panel-title a .ucws-icon {
    transform: rotateX(180deg);
    color: #fff
}

.block.accordion.solid .panel-heading {
    background-color: #f4f4f4
}

.block.accordion.border .panel-heading {
    background-color: #fff;
    border: 2px solid #63656A
}

.block.accordion .panel-body {
    padding: 15px
}

.block.accordion .panel-body > * {
    margin-top: 15px;
    margin-bottom: 30px
}

.block.accordion .panel-body > *:last-child {
    margin-bottom: 15px
}

.block.accordion .panel-body .section-header *:last-child {
    margin-bottom: 0
}

.block.accordion ol.section-items {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    counter-reset: checklist;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 0
}

.block.accordion ol.section-items > li {
    float: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-bottom: 2px solid #63656A;
    margin: 30px 0 0 15px;
    width: calc(100% - 30px)
}

.block.accordion ol.section-items > li:nth-child(1) {
    margin-top: 0
}

.block.accordion ol.section-items > li:not(:last-child) {
    padding-bottom: 30px
}

.block.accordion ol.section-items > li:last-child {
    border-bottom: none
}

.block.accordion ol.section-items > li::before {
    display: block;
    counter-increment: checklist 1;
    content: counter(checklist);
    font-size: 36pt;
    color: #63656A;
    line-height: 1em;
    font-weight: bold;
    margin-top: 0
}

.block.accordion ol.section-items > li ol > li > p {
    display: inline
}

.block.accordion ol.section-items > li > *:last-child {
    margin-bottom: 0
}

.block.accordion ol.section-items > li.no-counter {
    vertical-align: top;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)
}

.block.accordion ol.section-items > li.no-counter ul > li, .block.accordion ol.section-items > li.no-counter ol > li {
    list-style-image: none
}

.block.accordion ol.section-items > li.no-counter::before {
    content: none
}

.block.accordion ol.section-items .btn-wrapper {
    margin-top: 10px
}

@media only screen and (min-width: 768px) {
    .layout .layout-12 .block.accordion ol.section-items > li, .layout .layout-9 .block.accordion ol.section-items > li, .layout .layout-8 .block.accordion ol.section-items > li, .layout .layout-6 .block.accordion ol.section-items > li {
        padding-right: 15px;
        width: calc(50% - 15px);
        padding-bottom: 0 !important;
        border-bottom: none;
        border-right: 2px solid #63656A
    }

    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(-n+2), .layout .layout-9 .block.accordion ol.section-items > li:nth-child(-n+2), .layout .layout-8 .block.accordion ol.section-items > li:nth-child(-n+2), .layout .layout-6 .block.accordion ol.section-items > li:nth-child(-n+2) {
        margin-top: 0
    }

    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(odd), .layout .layout-9 .block.accordion ol.section-items > li:nth-child(odd), .layout .layout-8 .block.accordion ol.section-items > li:nth-child(odd), .layout .layout-6 .block.accordion ol.section-items > li:nth-child(odd) {
        clear: left
    }

    .layout .layout-12 .block.accordion ol.section-items > li:last-child, .layout .layout-12 .block.accordion ol.section-items > li:nth-child(even), .layout .layout-9 .block.accordion ol.section-items > li:last-child, .layout .layout-9 .block.accordion ol.section-items > li:nth-child(even), .layout .layout-8 .block.accordion ol.section-items > li:last-child, .layout .layout-8 .block.accordion ol.section-items > li:nth-child(even), .layout .layout-6 .block.accordion ol.section-items > li:last-child, .layout .layout-6 .block.accordion ol.section-items > li:nth-child(even) {
        border-right: none
    }
}

@media only screen and (min-width: 992px) {
    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(-n+5) {
        margin-top: 0
    }

    .layout .layout-12 .block.accordion ol.section-items > li:nth-child(odd) {
        clear: none
    }

    .layout .layout-12 .block.accordion ol.section-items > li:not(:last-child) {
        border-right: 2px solid #63656A
    }

    .layout .layout-12 .block.accordion ol.section-items.two-items > li {
        width: calc(50% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.three-items > li {
        width: calc(33.3% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.four-items > li {
        width: calc(25% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.max-items > li {
        width: calc(20% - 15px)
    }

    .layout .layout-12 .block.accordion ol.section-items.max-items > li:nth-child(5n+1) {
        clear: left
    }

    .layout .layout-12 .block.accordion ol.section-items.max-items > li:nth-child(5n) {
        border-right: none
    }
}

.f_textBox {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('');
    background-position: 0px 0px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .f_textBox:focus {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
  }


  @media screen and (max-width: 760px) {
    .jumbo_pad{
      padding: 20px;
      padding-right: 100px;
    }
    
  }


.col-image-banner img {
    height: 400px !important;
}
.col-text {
    position: absolute !important;
    top: 200px !important;
}

.col-text .cta-wrapper .cta-content {
    max-width: 775px !important;
}

@media screen and (min-width:335px) {
    .col-text{
        color:white;
        
    }
}


@media screen and (max-width:400px) {
    .col-image-banner img {
        height: 200px !important;
    }
    .col-text {
        color:white;
        position: absolute !important;
        top: 180px !important;
        font-size: 12px;
    }
}




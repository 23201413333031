.container-fluid .why-we-stand-out {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 1.25rem;
}

.discover-section {
    text-align: justify;
}

.discover-section .button {
    margin-bottom: 0px;
}


.discover-section .bordered-dark {
    background-color: transparent !important;
    border: 1px solid #2f2f2f !important;
    color: #2f2f2f !important;
}

.discover-section .button {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color .25s ease-out, color .25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.85em 1em;
    margin: 0 0 1rem;
    font-size: 1.5rem;
    background-color: #2199e8;
    color: #fefefe;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.w3-animate-fading2 {
    animation: fading 13s infinite;
}

.image-zoom:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.bg-light {
    background-color: #f8f9fa !important;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #63656A;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-text {
    font-size: 14px;
}

.count {
    border-radius: '10px';
    background-image: url(../../images/count-bg.jpg);
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center !important;
    /* float: center; */
    width: 100%;
    padding: 40px 0;
    color: #ffffff;
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.icon {
    padding-top: 20px;
    /* border: 1px solid rgba(255,255,255,0.2); */
    display: inline-block;
    width: 320px;
    height: 120px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.seperator-small {
    width: 10%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.47);
    margin: 15px auto;
}

.icons {
    font-size: 36px !important;
    margin-top: '10px';
}

.icon i {
    font-size: 56px !important;
    margin-top: '10px';
}

.count .col-xs-6 p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.counter {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    margin-top: 10px;
}

@media screen and (max-width: 400px) {
    .icon {
        padding-top: 20px;
        /* border: 1px solid rgba(255,255,255,0.2); */
        display: inline-block;
        width: 320px;
        height: 120px;
    }

    .seperator-small {
        width: 10%;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.47);
        margin: auto;
    }

    .icons {
        font-size: 26px !important;
        margin-top: '10px';
    }

    .count .col-xs-6 p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .counter {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 25px;
    }

    .marginb-5 {
        margin-bottom: 40px;
    }
}

.video-wrapper {
    position: relative;
}

.video-wrapper>video {
    width: 100%;
    /*vertical-align: middle;*/
}



.r-background {
    background: url('https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg');
    background-position: center;
    background-size: cover;
    height: 225px;
    width: 100%;
}

@media (min-width: 480px) {
    .r-background {
        height: 200px;
    }
}

@media (min-width: 768px) {
    .r-background {
        height: 150px;
    }
}

@media (min-width: 992px) {
    .r-background {
        height: 100px;
    }
}


.icons_i {
    font-size: 90px !important;
    margin-top: '10px';
}

.icon_i_blue {
    color: rgb(2, 0, 36) !important;
}


.icon_i_red {
    color: rgb(82, 7, 7) !important;
    
}

.icon_i_green {
    color: rgb(8, 73, 77) !important;
}

.icon_i_black {
    color: rgb(2, 0, 36) !important;
}

.icons_text {
    font-size: 20px !important;
}

.icon_i i {
    font-size: 76px !important;
    margin-top: '10px';
}


@media screen and (max-width: 450px) {
    .icons_i {
        font-size: 30px !important;
        margin-top: '10px';
    }
    .icons_text {
        font-size: 15px !important;
    }
    
    .icon_i i {
        font-size: 76px !important;
        margin-top: '10px';
    }
    .sm-margin{
        margin-bottom: 30px;
    }
  }

 
.block_quate {
    border-left: 4px solid #999999;
}

.block.more-info div.more-info-items {
    padding-left: 0;
    margin: 0 -15px;
}

/*.icon {*/
/*    width: 5% !important;*/
/*    padding-top: 0 !important;*/
/*}*/

